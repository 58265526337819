import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>
      <a id="unearthed-arcana"></a>Unearthed Arcana
    </h2>
    <h3>
      <a id="racial-paragon-classes"></a>RACIAL PARAGON CLASSES
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td valign="top">
            <a style={{
              "color": "#579eb6"
            }} href="#drow-paragon">Drow Paragon</a>
            <a style={{
              "color": "#579eb6"
            }} href="#dwarf-paragon">Dwarf Paragon</a>
            <a style={{
              "color": "#579eb6"
            }} href="#elf-paragon">Elf Paragon</a>
          </td>
          <td valign="top">
            <a style={{
              "color": "#579eb6"
            }} href="#gnome-paragon">Gnome Paragon</a>
            <a style={{
              "color": "#579eb6"
            }} href="#half-dragon-paragon">Half-Dragon Paragon</a>
            <a style={{
              "color": "#579eb6"
            }} href="#half-elf-paragon">Half-Elf Paragon</a>
          </td>
          <td valign="top">
            <a style={{
              "color": "#579eb6"
            }} href="#half-orc-paragon">Half-Orc Paragon</a>
            <a style={{
              "color": "#579eb6"
            }} href="#halfling-paragon">Halfling Paragon</a>
            <a style={{
              "color": "#579eb6"
            }} href="#human-paragon">Human Paragon</a>
          </td>
          <td valign="top">
            <a style={{
              "color": "#579eb6"
            }} href="#orc-paragon">Orc Paragon</a>
            <a style={{
              "color": "#579eb6"
            }} href="#tiefling-paragon">Tiefling Paragon</a>
          </td>
        </tr>
      </tbody>
    </table>
In most campaigns, the ideas of class and race are separate
concepts. But with racial paragon classes, the line between the two
becomes blurred. Racial paragons are, as their name suggests, nearly
ideal examples of the strengths and abilities of the character's
race. Unlike members of other classes, however, racial paragons are
more than merely powerful individuals. They are strong in all the
ways that their race is strong, while still vulnerable in the ways
that their race is vulnerable. Beyond that, they possess powers or
capabilities that supersede those of normal members of their race.
While any elf might rise in power, prestige, and (in game terms)
level, most do so by gaining levels in the standard character
classes � only a few are so much in tune with their heritage and
racial abilities that they become racial paragons.
    <p>
Racial paragons rarely undergo the rigorous training or study that
members of other classes commonly undertake between levels. Their
experience, wisdom, and heroic abilities simply manifest in the
form of superior innate racial abilities. Despite this strong
association with race, racial paragons need not have specific
views or special dedication to their race's beliefs or typical
attitudes (although many do).
    </p>
    <p>
Obviously, a character can only take levels in the racial paragon
class associated with his race. A human cannot take levels in
dwarf paragon � only dwarves are capable of reflecting the highest
virtues of dwarfhood by gaining levels in the dwarf paragon class.
    </p>
(Half-elves and half-orcs are an exception; see the <a style={{
      "color": "#579eb6"
    }} href="#half-elf-paragon">Half-Elf Paragon</a> and <a style={{
      "color": "#579eb6"
    }} href="#half-orc-paragon">Half-Orc Paragon</a> sections below.)
    <p>
Like the fighter, the wizard, and the other standard character
classes, the racial paragon classes have no prerequisites (other
than being a member of the appropriate race). Paragon class levels
can be taken any time a character gains a new level, even at 1st
level (in which case they receive four times the normal number of
skill points gained at each succeeding level). A character can
multiclass freely between standard character classes, prestige
classes for which he or she qualifies, and the character's
appropriate racial paragon class.
    </p>
    <p>
It's possible for a powerful magic effect such as <a style={{
        "color": "#579eb6"
      }} href="spellsS.html#shapechange">shapechange</a>,
      <a style={{
        "color": "#579eb6"
      }} href="spellsPtoR.html#reincarnate">reincarnate</a>, or <a style={{
        "color": "#579eb6"
      }} href="spellsTtoZ.html#wish">wish</a> to change a character's race. If a
character has already taken racial paragon levels in his original
race, he can never become a paragon of another race. However, such
shapechanging and form-altering magics also cause no loss of a
paragon's class abilities � the class abilities gained from racial
paragon levels are affected no more or less drastically than
benefits gained from having levels in any other class.
    </p>
    <p>
Levels in racial paragon classes never result in XP penalties for
multiclass characters.
    </p>
    <h5>
      <a id="subraces-and-paragon-classes"></a> SUBRACES AND PARAGON
CLASSES
    </h5>
    <p>
As a general rule, a member of a subrace can take levels in the
standard race's paragon class unless a specific paragon class
exists for the subrace. For example, <a style={{
        "color": "#579eb6"
      }} href="monstersEtoF.html#aquatic-elf">aquatic elves</a>,
      <a style={{
        "color": "#579eb6"
      }} href="monstersEtoF.html#gray-elf">gray elves</a>,
      <a style={{
        "color": "#579eb6"
      }} href="monstersEtoF.html#wild-elf">wild elves</a>, and
      <a style={{
        "color": "#579eb6"
      }} href="monstersEtoF.html#wood-elf">wood elves</a> may all advance as
      <a style={{
        "color": "#579eb6"
      }} href="#elf-paragon">elf paragons</a>, but <a style={{
        "color": "#579eb6"
      }} href="monstersEtoF.html#drow-elf">drow elves</a> may not, because drow
have a <a style={{
        "color": "#579eb6"
      }} href="#drow-paragon">separate paragon class</a>. If you
wanted to further differentiate the elven subraces by creating a
paragon class for one or more of the subraces, those subraces
could not then take levels of elf paragon.
    </p>
    <p>
When deciding whether to create new paragon classes for subraces
in your game, consider how different the subrace is from the main
race. For instance, the <a style={{
        "color": "#579eb6"
      }} href="monstersEtoF.html#gray-elf">gray
elf</a> is very similar to the standard <a style={{
        "color": "#579eb6"
      }} href="monstersEtoF.html#elf">(high) elf</a>, but both the <a style={{
        "color": "#579eb6"
      }} href="monstersEtoF.html#wild-elf">wild elf</a> and <a style={{
        "color": "#579eb6"
      }} href="monstersEtoF.html#wood-elf">wood elf</a> receive a penalty to
Intelligence, suggesting that the <a style={{
        "color": "#579eb6"
      }} href="#elf-paragon">elf
paragon's</a> Intelligence increase at 3rd level might not be
appropriate for those races. The <a style={{
        "color": "#579eb6"
      }} href="monstersG.html#forest-gnome">forest gnome</a> is very similar to
the standard <a style={{
        "color": "#579eb6"
      }} href="monstersG.html#gnome">(rock) gnome</a>, but
the <a style={{
        "color": "#579eb6"
      }} href="monstersG.html#svirfneblin-gnome">svirfneblin</a> is
so different (including a +3 level adjustment) that it probably
deserves its own paragon class.
    </p>
    <h5>
      <a id="paragon-classes-in-your-game"></a> PARAGON CLASSES IN
YOUR GAME
    </h5>
    <p>
Like many of the variants rules, racial paragons can be a powerful
tool for shaping a campaign world or play experience. Including
racial paragons in a campaign is as easy as allowing players to
advance in the classes or designing NPCs with class levels from
the appropriate paragon class. However, this variant can be more
than just another long list of possible classes. By allowing
different subsets of the racial paragon classes to be present in
your game, you can shape which races are the most influential or
numerous in the campaign.
    </p>
    <p>
For example, in a setting in which elves and dwarves have all but
died out, the traditions of their racial paragon classes might
have been lost. In the same campaign, however, humans, half-orcs,
and halflings all thrive, and powerful NPCs of these races might
frequently have a level or two in the appropriate paragon class.
    </p>
    <p>
Furthermore, the prevalence of racial paragons can reflect the
importance of racial issues. In campaigns where tensions run high
between the civilized races, each race is more likely to have a
large number of racial paragons among its members. As the cause of
expanding humanity's borders into the wilderness becomes more and
more important to the race, more human paragons come to the fore �
while halflings in the same campaign, content and secure with
their lot in life, turn to other classes and pursuits less tied to
the goals of their race.
    </p>
    <p>
You might choose to create your own racial paragon classes. When
creating a racial paragon class for a race with a level adjustment
of +1 or higher (such as drow or half-dragon), it's acceptable for
the class to be a little better than a paragon class for a race
with a level adjustment of +0. In general, the benefits of a race
with a level adjustment tend to decrease in value as the character
gains levels, and an above-average paragon class can help that
race maintain its edge.
    </p>
    <h3><a id="drow-paragon"></a>
DROW PARAGON
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/UnA_Gallery/79144.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a>
Separated from the rest of the elven people by a legacy of deceit,
betrayal, and evil, the drow are easily one of the most dangerous
of the humanoid races. Most drow paragons embody all their race's
evil nature and delight in calculated cruelty, but some manage to
pull away from this dark path, instead embodying the potential
that all elves share for beauty, grace, and martial skill.
    <p>
Drow society is full of intrigue and fierce competition. Even the
most able drow paragons can do little more to advance their race
than to strive against the machinations of other powerful drow.
When they succeed in breaking away from drow culture and its cruel
influence, however, good-aligned drow paragons can be the most
able and successful members of their race.
    </p>
    <p>
      <a id="drow-paragon-adventures"></a><b>Adventures:</b> Drow paragons adventure for one of two reasons.
Those of an evil bent adventure to increase their own personal
power, by acquiring wealth and powerful magic items or by
improving their combat skills. Those few drow paragons with less
selfish motives adventure to remove themselves from the influence
of their race's harsh and cruel society. These latter drow
paragons are the ones most likely to be seen in the company of
other races.
    </p>
    <p>
      <a id="drow-paragon-characteristics"></a><b>Characteristics:</b> Drow paragons are typically more reserved
than other members of their race. Drow paragons consider
themselves the elite of drow society, although they are careful
about sharing these viewpoints with the powerful priestesses that
hold sway over most aspects of drow culture. If anything, drow
paragons are even more secretive and devious than average members
of their race, and their success in such dangerous society
emphasizes their ability to navigate treacherous social
environments and manipulate the actions of others. It is said that
the day a drow stops scheming is the day of her death.
    </p>
    <p>
      <a id="drow-paragon-alignment"></a><b>Alignment:</b> The vast majority of drow, including most drow
paragons, are unrelentingly evil. However, the few good-aligned
drow who survive their formative years are actually quite likely
to gain levels in the drow paragon class. This makes good-aligned
drow paragons more frequent than good drow of other classes, but
still nowhere near as common as evil-aligned drow paragons.
    </p>
    <p>
      <a id="drow-paragon-religion"></a><b>Religion:</b> Much of drow culture is dominated by religion,
and the powerful priestesses who rule drow society see drow
paragons as tools � more skilled than other ambitious drow, perhaps,
but no more inherently valuable. This outlook chafes against the
drow paragons own belief that she is the elite of her race. As a
result, drow paragons are unlikely to follow religion of any kind
when removed from the direct influence of the drow priestesses.
    </p>
    <p>
      <a id="drow-paragon-background"></a><b>Background:</b> Drow paragons learn their skills just as other
drow do: by succeeding where weaker drow fail. Drow paragons build
their skills among the hidden dangers of the subterranean realms
and in a society where the powerful freely prey upon the weak.
Regardless of alignment or other abilities, drow paragons are all
forced at some point to make a decision about their race's cruel
and everpresent religion. Those drow paragons who choose to follow
the tenets of the evil priestesses often rise to become deadly
lieutenants, carrying out the will of the matriarchs. The few who
reject the priestesses' teachings must do so quietly and covertly,
biding their time until they can escape the influence of other
drow.
    </p>
    <p>
      <a id="drow-paragon-races"></a><b>Races:</b> Most drow paragons have little use for members of
the other races, viewing them only as potential prey or slaves.
Good-aligned drow paragons, however, see the merits of interracial
cooperation. These paragons seek out adventurers of other races,
often coming to relate more to the societies of their adventuring
companions than to their own culture.
    </p>
    <p>
      <a id="drow-paragon-other-classes"></a><b>Other Classes:</b> Drow paragons see members of other classes
as tools. They value a broad skill set in those with whom they
associate, provided they can control the actions of these
associates when the need arises. Drow paragons are willing to work
with anyone they can manipulate to their own ends.
    </p>
    <p>
That said, drow paragons often see fighters as ideal companions
because they combine great martial proficiency with physical
prowess, while at the same time they are typically incapable of
matching the drow's stealth and subtlety�ensuring that the drow
paragon can gain the upper hand whenever desired.
    </p>
    <h5><a id="drow-paragon-game-rule-information"></a>
GAME RULE INFORMATION
    </h5>
    <b><a id="table-the-drow-paragon"></a>Table: The Drow Paragon</b>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "80%"
    }}>
      <tbody>
        <tr>
          <th>
Level
          </th>
          <th>
Base
Attack
Bonus
          </th>
          <th>
Fort
Save
          </th>
          <th>
Ref
Save
          </th>
          <th>
Will
Save
          </th>
          <th align="left">
Special
          </th>
          <th align="left">
Spells per Day
          </th>
        </tr>
        <tr className="odd-row">
          <td align="center">
1st
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+0
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#drow-paragon-improved-spell-like-abilities">improved spell-like abilities</a>
          </td>
          <td>
+1 level of cleric or wizard
          </td>
        </tr>
        <tr>
          <td align="center">
2nd
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+0
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#drow-paragon-improved-darkvision">Improved darkvision</a> (+30 ft.),
            <a style={{
              "color": "#579eb6"
            }} href="#drow-paragon-light-sensitivity">light sensitivity</a>
          </td>
          <td>
&mdash;
          </td>
        </tr>
        <tr className="odd-row">
          <td align="center">
3rd
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+1
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#drow-paragon-ability-boost">Ability boost</a> (Dex +2)
          </td>
          <td>
+1 level of cleric or wizard
          </td>
        </tr>
      </tbody>
    </table>
    <p>
Drow paragons have the following game statistics.
    </p>
    <p>
      <b>Abilities:</b> Drow paragons favor fighting from ambush, making
Dexterity important for its role in sneaking and striking with
ranged weapons. Wisdom helps in detecting a potential foe before
the foe detects the drow in turn. Strength is also important due
to its obvious role in melee combat.
    </p>
    <p>
      <b>Alignment:</b> Any.
    </p>
    <p>
      <b>Hit Die:</b> d6.
    </p>
    <h6><a id="drow-paragon-class-skills"></a>
Class Skills
    </h6>
    <p>
The drow paragon's class skills (and the key ability for each
skill) are <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#climb">Climb</a> (Str), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#craft">Craft</a> (Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#hide">Hide</a> (Dex), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#intimidate">intimidate</a> (Cha),
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#jump">Jump</a> (Str), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#knowledge">Knowledge</a> (all skills, taken individually) (Int),
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#listen">Listen</a> (Wis), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#move-silently">Move Silently</a> (Dex), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#profession">Profession</a> (Wis), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#spellcraft">Spellcraft</a>
(Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#spot">Spot</a> (Wis), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#survival">Survival</a> (Wis), and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#swim">Swim</a> (Str).
    </p>
    <p>
      <b>Skill Points at Each Level:</b> 4 + Int modifier.
    </p>
    <h6><a id="drow-paragon-class-features"></a>
Class Features
    </h6>
    <p>
All of the following are class features of the drow paragon class.
    </p>
    <p>
      <b>Weapon and Armor Proficiency:</b> Drow paragons are proficient
with all simple weapons, rapiers, longswords, and hand crossbows,
and with light armor.
    </p>
    <p>
      <b>Spells per Day:</b> At 1st and 3rd level, a female drow paragon
gains new spells per day as if she had also gained a level in
      <a style={{
        "color": "#579eb6"
      }} href="cleric.html">cleric</a>. A male drow paragon instead gains new spells per day as if
he had gained a level of <a style={{
        "color": "#579eb6"
      }} href="wizard.html">wizard</a>. The paragon does not, however,
gain any other benefit a character of that class would have gained
(bonus metamagic feats, and so on). This essentially means that
the character adds the level of drow paragon to her level in
cleric or wizard, then determines spells per day and caster level
accordingly.
    </p>
    <p>
If the drow paragon is female and has no levels in cleric, or is
male and has no levels in wizard, this class feature has no
effect.
    </p>
    <p>
      <a id="drow-paragon-improved-spell-like-abilities"></a><b>Improved Spell-Like Abilities:</b> At 1st level, a drow paragon
gains one additional daily use of each of her innate spell-like
abilities.
    </p>
    <p>
      <a id="drow-paragon-improved-darkvision"></a><b>Improved Darkvision (Ex):</b> At 2nd level, a drow paragon's
darkvision range increases by 30 feet.
    </p>
    <p>
      <a id="drow-paragon-light-sensitivity"></a><b>Light Sensitivity (Ex):</b> A 2nd-level drow paragon's light
blindness is lessened to light sensitivity. Instead of being
      <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#blinded">blinded</a> by bright light, she is merely <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#dazzled">dazzled</a> in bright sunlight
or within the radius of a <a style={{
        "color": "#579eb6"
      }} href="spellsDtoE.html#daylight">daylight</a> spell.
    </p>
    <p>
      <a id="drow-paragon-ability-boost"></a><b>Ability Boost (Ex):</b> At 3rd level, a drow paragon's
Dexterity score increases by 2 points.
    </p>
    <h3><a id="dwarf-paragon"></a>
DWARF PARAGON
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/UnA_Gallery/79141.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a>
Known for their skill in warfare, their ability to withstand
physical and magical punishment, and their great love of stone and
stonework, dwarves grow as strong as their mountain homes. Dwarf
paragons exemplify this strength, gaining a deeper intuitive
understanding of stone and stonework, building upon their already
proven toughness, and promoting the crafts and strengths of their
people whenever possible.
    <p>
Dwarf paragons are doughty warriors, and powerful paragons often
take the lead in battles as well as diplomatic engagements.
    </p>
    <p>
      <a id="dwarf-paragon-adventures"></a><b>Adventures:</b> Dwarf paragons stand at the forefront of their
people, ready to defend or promote the interests of their race
through word and deed. Although they are as disparate a group as
the members of any character class, most dwarf paragons undertake
adventure freely, as along as it does not threaten or endanger a
dwarven community.
    </p>
    <p>
      <a id="dwarf-paragon-charactersistics"></a><b>Characteristics:</b> Dwarf paragons are tough, skilled warriors
who are most at home when deep underground. Because of their
martial focus, dwarf fighters and paladins are likely to become
paragons, although many clerics also find that their race's innate
abilities and interests blend well with their divine training, and
they frequently take levels in dwarf paragon as well. Because of
their race's ability with weaponsmithing and other crafts, dwarf
paragons are likely to look down upon weapons and gear made by
members of other races.
    </p>
    <p>
      <a id="dwarf-paragon-alignment"></a><b>Alignment:</b> Dwarf paragons can be of any alignment, but
their interest in the success of their communities and their
ingrained sense of dwarven honor ensures that many act in a lawful
and good manner. Neutral dwarf paragons are found more frequently
in areas where relations with other good races have become
strained, or where the different races focus more on the interests
of their people than on morality.
    </p>
    <p>
      <a id="dwarf-paragon-religion"></a><b>Religion:</b> Religion is an important part of many dwarven
communities, and thus dwarf paragons often exemplify this aspect
of dwarven life as they exemplify all others. Many dwarf paragons
are also clerics of dwarven deities, and the two philosophies
support and reinforce each other.
    </p>
    <p>
      <a id="dwarf-paragon-background"></a><b>Background:</b> Although many dwarf paragons grow into their
power in the hearts of strong and strident dwarven communities,
some rise from areas where dwarves have been oppressed or even
defeated. Whatever their origins, dwarf paragons are tough,
determined folk who seek out specialized training and master
complex tasks to further the aims of their people. Dwarf paragons
are often descended from heroes of older generations, and these
individuals may seek to honor their ancestors' memory through
deeds of their own.
    </p>
    <p>
      <a id="dwarf-paragon-races"></a><b>Races:</b> Dwarf paragons are all dwarves, of course, and like
other racial paragons, their actions and efforts are motivated
primarily by the interests of their people. Despite their focus on
their own race's innate abilities, they remain surprisingly open
to adventuring with members of other races. In most cases, it is
practicality that drives a paragon more than anything else once a
dwarf paragon has decided on a course of action, he uses whatever
and whomever is at hand to accomplish the task.
    </p>
    <p>
Dwarf paragons, like other members of their race, consider the
building of trust and friendship a long, slow task that cannot be
rushed. Although it might take a hundred years or more to earn a
dwarf paragon's complete trust, dwarf paragons are by no means a
suspicious lot.
    </p>
    <p>
      <a id="dwarf-paragon-other-classes"></a><b>Other Classes:</b> In general, the reactions of a dwarf paragon
are governed more by how an individual or race interacts with the
dwarven community than by class or personal training but some
general attitudes are common to most dwarf paragons. Dwarf
paragons generally get along well with dwarf fighters, paladins,
and clerics, although they appreciate anyone who is willing to
work in the best interests of the dwarven people. Dwarf paragons
see wizards and sorcerers as important allies in the defense and
promotion of the dwarven people, but powerful spellcasters of any
kind can pose a threat to an entire community, making dwarf
paragons wary of their power.
    </p>
    <h5><a id="dwarf-paragon-game-rule-information"></a>
GAME RULE INFORMATION
    </h5>
    <b><a id="table-the-dwarf-paragon"></a>Table: The Dwarf Paragon</b>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "80%"
    }}>
      <tbody>
        <tr>
          <th>
Level
          </th>
          <th>
Base
Attack
Bonus
          </th>
          <th>
Fort
Save
          </th>
          <th>
Ref
Save
          </th>
          <th>
Will
Save
          </th>
          <th align="left">
Special
          </th>
        </tr>
        <tr className="odd-row">
          <td align="center">
1st
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+0
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#dwarf-paragon-craft-expertise">Craft expertise</a>, <a style={{
              "color": "#579eb6"
            }} href="#dwarf-paragon-improved-stonecunning">improved stonecunning</a>
          </td>
        </tr>
        <tr>
          <td align="center">
2nd
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+0
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#dwarf-paragon-improved-darkvision">Improved darkvision</a> (+30 ft.), <a style={{
              "color": "#579eb6"
            }} href="#dwarf-paragon-save-bonus">save bonus</a>
          </td>
        </tr>
        <tr className="odd-row">
          <td align="center">
3rd
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
+1
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#dwarf-paragon-ability-boost">Ability boost</a> (Con +2)
          </td>
        </tr>
      </tbody>
    </table>
    <p>
Dwarf paragons have the following game statistics.
    </p>
    <p>
      <b>Abilities:</b> Strength is important for dwarf paragons because
they often serve as defenders of their people and emphasize their
own martial training. Constitution is important not only to a
dwarf paragon's ability to stay on his feet in battle, but also
because physical toughness is a hallmark of the dwarf race.
    </p>
    <p>
      <b>Alignment:</b> Any.
    </p>
    <p>
      <b>Hit Die:</b> d10.
    </p>
    <h6><a id="dwarf-paragon-class-skills"></a>
Class Skills
    </h6>
    <p>
The dwarf paragon's class skills (and the key ability for each
skill) are <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#appraise">Appraise</a> (Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#climb">Climb</a> (Str), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#craft">Craft</a> (Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#intimidate">Intimidate</a>
(Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#jump">Jump</a> (Str), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#knowledge">Knowledge</a> (dungeoneering) (Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#profession">Profession</a>
(Wis), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#sense-motive">Sense Motive</a> (Wis), and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#survival">Survival</a> (Wis).
    </p>
    <p>
      <b>Skill Points at Each Level:</b> 2 + Int modifier.
    </p>
    <h6><a id="dwarf-paragon-class-features"></a>
Class Features
    </h6>
    <p>
All of the following are class features of the dwarf paragon
class.
    </p>
    <p>
      <b>Weapon and Armor Proficiency:</b> Dwarf paragons are proficient
with all simple and martial weapons, with light, medium, and heavy
armor, and with shields (but not with tower shields).
    </p>
    <p>
      <a id="dwarf-paragon-craft-expertise"></a><b>Craft Expertise (Ex):</b> A dwarf paragon may add his class
level as a racial bonus on all <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#craft">Craft</a> checks related to stone or
metal items.
    </p>
    <p>
      <a id="dwarf-paragon-improved-stonecunning"></a><b>Improved Stonecunning (Ex):</b> At 1st level, a dwarf paragon's
racial bonus on <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#search">Search</a> checks to notice unusual stonework
increases to +4. The range at which he receives an automatic
Search check for coming near an example of unusual stonework
increases to 20 feet. Also, he can intuit where true north lies in
relation to himself (as if he had 5 ranks in <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#survival">Survival</a>) whenever
underground.
    </p>
    <p>
      <a id="dwarf-paragon-improved-darkvision"></a><b>Improved Darkvision (Ex):</b> At 2nd level, a dwarf paragon's
darkvision range increases by 30 feet.
    </p>
    <p>
      <a id="dwarf-paragon-save-bonus"></a><b>Save Bonus (Ex):</b> A 2nd-level dwarf paragon's racial bonus
on saves against <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#poison">poison</a> and against spells and spell-like effects
increases by 1.
    </p>
    <p>
      <a id="dwarf-paragon-ability-boost"></a><b>Ability Boost (Ex):</b> At 3rd level, a dwarf paragon's
Constitution score increases by 2 points.
    </p>
    <h3><a id="elf-paragon"></a>
ELF PARAGON
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/UnA_Gallery/79143.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a>
Accomplished with both spell and blade, elves move through the
world at a pace removed from that of shorter-lived races.
Long-standing champions of good, the elf race is storied beyond
telling and peopled by heroes beyond counting. As mighty as the
elf race can be, it is not often that elves turn to warfare or
combat, preferring instead the paths of poetry, dance, song, and
lore. With their long life spans and many talents, elves excel in
numerous areas and classes, but a few exemplify to an even greater
degree the complex and powerful nature of their race.
    <p>
      <a id="elf-paragon-adventures"></a><b>Adventures:</b> Elf paragons retreat from the world less
readily than most elves, seeing much of the nature of the elf race
in the form and shape of the world. They enjoy travel and
interaction with other races, learning as much about themselves in
the process as they do about those they meet. Elf paragons are
aware that voluntary isolation from other good-aligned races poses
a great danger to the elven race � although such self-absorption
lessens the pain and frustration involved in dealing with other
races, it leaves an elven community vulnerable to stagnation and
decline.
    </p>
    <p>
      <a id="elf-paragon-characteristics"></a><b>Characteristics:</b> Elf paragons are curious, patient, and,
when roused to action, deadly. They are swift and stealthy and
have amazingly acute senses, allowing them to react far faster
than opponents might expect. Their aptitude in both stealth and
weapons makes them excellent at attacking from ambush, and their
propensity to take <a style={{
        "color": "#579eb6"
      }} href="rogue.html">rogue</a> and <a style={{
        "color": "#579eb6"
      }} href="ranger.html">ranger</a> levels in addition to their
paragon levels accentuates these strengths.
    </p>
    <p>
      <a id="elf-paragon-alignment"></a><b>Alignment:</b> Elf paragons can be of any alignment, but most
are neutral good. They see the world as a place of harmony, a
place that can provide bounty to all those willing to live in
peace so long as society is not pushed to extremes of law or
anarchy.
    </p>
    <p>
      <a id="elf-paragon-religion"></a><b>Religion:</b> Although elves as a whole can be as religious as
members of any other race, elf paragons rarely concentrate on
religion. Instead, they focus their attention and devotion on the
mortal members of their race.
    </p>
    <p>
      <a id="elf-paragon-background"></a><b>Background:</b> Most elf paragons come into their abilities
through a strong love of their people and the work of an
influential mentor. Although this mentor may be of any class, it
is often the lorekeepers and wizards of the elf race who seek to
foster the skills of fledgling elf paragons, knowing that one
young elf turning to the path of the paragon can benefit the race
for nearly a thousand years.
    </p>
    <p>
      <a id="elf-paragon-races"></a><b>Races:</b> Elf paragons are, for the most part, open and
gracious when dealing with members of other races. Even if she
secretly looks down on shorter-lived and less gifted folk, an elf
paragon knows that other races can offer her the one thing that
most elves lack: a sense of urgency. As an elf paragon grows in
power, she becomes more and more apt to look upon members of other
races as curiosities to be studied rather than as peers � but she
remains gracious and trusting, striving to learn how the virtues
of other races might benefit the elf race.
    </p>
    <p>
      <a id="elf-paragon-other-classes"></a><b>Other Classes:</b> Like most racial paragons, elf paragons tend
to think more in terms of relations with other races than they do
about interactions with other classes. Elf paragons relate best to
characters who revere nature or seek to live in harmony with the
world around them. They find common philosophical ground with
      <a style={{
        "color": "#579eb6"
      }} href="monk.html">monks</a> and common cause with many <a style={{
        "color": "#579eb6"
      }} href="paladin.html">paladins</a>, but they are most
comfortable around <a style={{
        "color": "#579eb6"
      }} href="wizard.html">wizards</a>, <a style={{
        "color": "#579eb6"
      }} href="ranger.html">rangers</a>, and <a style={{
        "color": "#579eb6"
      }} href="druid.html">druids</a>. Although elves
are as religious as any other race, elf paragons often mistrust
      <a style={{
        "color": "#579eb6"
      }} href="cleric.html">clerics'</a> reliance on divine guidance, and heavily armored warriors
have an approach to combat that elf paragons sometimes have a hard
time appreciating.
    </p>
    <h5><a id="elf-paragon-game-rule-information"></a>
GAME RULE INFORMATION
    </h5>
    <b><a id="table-the-elf-paragon"></a>Table: The Elf Paragon</b>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "80%"
    }}>
      <tbody>
        <tr>
          <th>
Level
          </th>
          <th>
Base
Attack
Bonus
          </th>
          <th>
Fort
Save
          </th>
          <th>
Ref
Save
          </th>
          <th>
Will
Save
          </th>
          <th align="left">
Special
          </th>
          <th align="left">
Spells per Day
          </th>
        </tr>
        <tr className="odd-row">
          <td align="center">
1st
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+0
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#elf-paragon-elfsight">Elfsight</a>, <a style={{
              "color": "#579eb6"
            }} href="#elf-paragon-resist-enchantments">resist enchantments</a>
          </td>
          <td>
&mdash;
          </td>
        </tr>
        <tr>
          <td align="center">
2nd
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+0
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#elf-paragon-weapon-focus">Weapon Focus</a>
          </td>
          <td>
+1 level of wizard
          </td>
        </tr>
        <tr className="odd-row">
          <td align="center">
3rd
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+1
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#elf-paragon-ability-boost">Ability boost</a> (Int +2)
          </td>
          <td>
+1 level of wizard
          </td>
        </tr>
      </tbody>
    </table>
    <p>
Elf paragons have the following game statistics.
    </p>
    <p>
      <b>Abilities:</b> Agility and physical grace are hallmarks of the
elf race, and a high Dexterity score helps an elf paragon avoid
blows in combat and make more accurate ranged attacks. Dexterity
can even (after the selection of the <a style={{
        "color": "#579eb6"
      }} href="featsAll.html#weapon-finesse">Weapon Finesse</a> feat) aid in
melee combat. A high Intelligence score allows an elf paragon to
access a broader selection of skills, and a high Wisdom is
important for some of the class's most important class skills.
    </p>
    <p>
      <b>Alignment:</b> Any.
    </p>
    <p>
      <b>Hit Die:</b> d8.
    </p>
    <h6><a id="elf-paragon-class-skills"></a>
Class Skills
    </h6>
    <p>
The elf paragon's class skills (and the key ability for each
skill) are <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#climb">Climb</a> (Str), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#craft">Craft</a> (Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#diplomacy">Diplomacy</a> (Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#hide">Hide</a> (Dex),
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#jump">Jump</a> (Str), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#knowledge">Knowledge</a> (all skills, taken individually) (Int),
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#listen">Listen</a> (Wis), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#move-silently">Move Silently</a> (Dex), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#profession">Profession</a> (Wis), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#spellcraft">Spellcraft</a>
(Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#spot">Spot</a> (Wis), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#survival">Survival</a> (Wis), and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#swim">Swim</a> (Str).
    </p>
    <p>
      <b>Skill Points at Each Level:</b> 2 + Int modifier.
    </p>
    <h6><a id="elf-paragon-class-features"></a>
Class Features
    </h6>
    <p>
All of the following are class features of the elf paragon class.
    </p>
    <p>
      <b>Weapon and Armor Proficiency:</b> Elf paragons are proficient
with all simple weapons, rapiers, longswords, shortbows, and
longbows. Elf paragons are proficient with light armor, but not
with shields.
    </p>
    <p>
      <b>Spells per Day:</b> At 2nd and 3rd level, an elf paragon gains
new spells per day as if she had also gained a level in <a style={{
        "color": "#579eb6"
      }} href="wizard.html">wizard</a>.
She does not, however, gain any other benefit a character of that
class would have gained (bonus metamagic feats, and so on). This
essentially means that she adds the level of elf paragon to her
level in wizard, then determines spells per day and caster level
accordingly.
    </p>
    <p>
If an elf paragon has no levels in wizard, this class feature has
no effect.
    </p>
    <p>
      <a id="elf-paragon-elfsight"></a><b>Elfsight (Ex):</b> An elf paragon has exceptional visual
acuity. Her racial bonus on <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#search">Search</a> and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#spot">Spot</a> checks increases to
+4. In addition, an elf paragon's low-light vision increases in
range, allowing her to see three times as far as a human in
starlight, moonlight, torchlight, and similar conditions of poor
illumination.
    </p>
    <p>
      <a id="elf-paragon-resist-enchantments"></a><b>Resist Enchantments (Ex):</b> An elf paragon's racial bonus on
saves against enchantment spells or effects increases by 2.
    </p>
    <p>
      <a id="elf-paragon-weapon-focus"></a><b>Weapon Focus (Ex):</b> At 2nd level, an elf paragon gains
      <a style={{
        "color": "#579eb6"
      }} href="featsAll.html#weapon-focus">Weapon Focus</a> as a bonus feat. This feat must apply to either the
rapier, longsword, shortsword, shortbow, longbow, composite
shortbow, or composite longbow.
    </p>
    <p>
      <a id="elf-paragon-ability-boost"></a><b>Ability Boost (Ex):</b> At 3rd level, an elf paragon's
Intelligence score increases by 2 points.
    </p>
    <h3><a id="gnome-paragon"></a>
GNOME PARAGON
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/UnA_Gallery/79142.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a>
Curious, intelligent, and likable, gnomes move easily through the
societies and communities of other races. They enjoy a strong
sense of loyalty to clan as well as the pull of new experiences
offered by adventure. While these sometimes opposing motivations
get some adventuring gnomes into trouble with family and clan,
gnome paragons successfully blend both aspects of their race's
nature. They serve as an example to others of how to promote and
protect their community and clan while at the same time constantly
searching the world for new and interesting opportunities.
    <p>
      <a id="gnome-paragon-adventures"></a><b>Adventures:</b> Gnome paragons adventure for the sake of
discovery. They are curious and social individuals who can balance
the demands of clan life with the joys of travel. Many gnome
paragons blend their love of adventure with their duties to their
clans by traveling and adventuring only during the summer months,
returning in the autumn to their homes with news and skills
learned during their travels.
    </p>
    <p>
      <a id="gnome-paragon-characteristics"></a><b>Characteristics:</b> Personable and curious, gnome paragons get
along well in most communities, and they often provide a lively
diversion wherever they go. Socially adept enough to know when
their questions are wearing thin on their companions, gnome
paragons nonetheless share their race's propensity for long
strings of seemingly unrelated questions. This lighthearted
behavior does not make them silly or weak, however; they are calm
and likable in most situations, but determined and sometimes
ruthless when protecting their friends and communities.
    </p>
    <p>
      <a id="gnome-paragon-alignment"></a><b>Alignment:</b> Gnome paragons are almost always good-aligned,
with an equal balance of neutrality and chaos represented among
the class.
    </p>
    <p>
      <a id="gnome-paragon-religion"></a><b>Religion:</b> Seldom religious themselves, gnome paragons are
curious about religion and sincerely interested in the traditions
of others. Those gnome paragons who do follow the teachings of one
deity almost always worship a deity of knowledge or travel, the
two disciplines that appeal most to the paragon's inquisitive
nature.
    </p>
    <p>
      <a id="gnome-paragon-background"></a><b>Background:</b> Gnome paragons have incredibly varied
backgrounds, much more so than the paragons of any other race
except humans. They travel unusual and individual paths toward
achieving their status as paragons, and most do so without
realizing it. Burgeoning gnome paragons are so involved in the
nature of their race and the love of new experience that they
seldom realize they champion and demonstrate the greatest virtues
of gnomekind.
    </p>
    <p>
      <a id="gnome-paragon-races"></a><b>Races:</b> Gnome paragons, like many other gnomes, prefer the
company of dwarves over that of other races�but they enjoy social
interaction with nearly any race. Gnome paragons are sincerely
interested in experiencing what other races have to offer, making
their company appealing to all but the most reclusive or staid
individuals. Gnome paragons are even willing to interact with
monstrous creatures, and good-aligned individuals from normally
evil races find themselves welcome in gnome communities due to the
efforts of gnome paragons.
    </p>
    <p>
      <a id="gnome-paragon-other-classes"></a><b>Other Classes:</b> Gnome paragons enjoy adventuring with
members of any class. They benefit greatly from fighting beside or
behind a larger, stronger melee combatant. They are adept in
social situations that many classes find taxing. And they provide
minor spellcasting support that benefits many combinations of
allies. Gnome paragons even get along well with the paragons of
other races, finding the magnified characteristics of racial
paragons both entertaining and instructive.
    </p>
    <h5><a id="gnome-paragon-game-rule-information"></a>
GAME RULE INFORMATION
    </h5>
    <b><a id="talble-the-gnome-paragon"></a>Table: The Gnome Paragon</b>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "80%"
    }}>
      <tbody>
        <tr>
          <th>
Level
          </th>
          <th>
Base
Attack
Bonus
          </th>
          <th>
Fort
Save
          </th>
          <th>
Ref
Save
          </th>
          <th>
Will
Save
          </th>
          <th align="left">
Special
          </th>
          <th align="left">
Spells per Day
          </th>
        </tr>
        <tr className="odd-row">
          <td align="center">
1st
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+2
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#gnome-paragon-illusion-aptitude">Illusion aptitude</a>, <a style={{
              "color": "#579eb6"
            }} href="#gnome-paragon-keen-senses">keen senses</a>
          </td>
          <td>
&mdash;
          </td>
        </tr>
        <tr>
          <td align="center">
2nd
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+3
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#gnome-paragon-mirror-image">Mirror image</a>
          </td>
          <td>
+1 level of bard
          </td>
        </tr>
        <tr className="odd-row">
          <td align="center">
3rd
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
+3
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#gnome-paragon-ability-boost">Ability boost</a> (Cha +2)
          </td>
          <td>
+1 level of bard
          </td>
        </tr>
      </tbody>
    </table>
    <p>
Gnome paragons have the following game statistics.
    </p>
    <p>
      <b>Abilities:</b> Charisma determines the effectiveness of a gnome
paragon's limited spellcasting ability, and it is important to the
social skills in which the class excels. Dexterity can help gnome
paragons survive combat with larger, stronger foes, and help make
them more effective as ranged combatants, while Constitution can
make them more able to withstand the physical dangers of
adventuring.
    </p>
    <p>
      <b>Alignment:</b> Any.
    </p>
    <p>
      <b>Hit Die:</b> d6.
    </p>
    <h6><a id="gnome-paragon-class-skills"></a>
Class Skills
    </h6>
    <p>
The gnome paragon's class skills (and the key ability for each
skill) are <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#bluff">Bluff</a> (Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#climb">Climb</a> (Str), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#craft">Craft</a> (Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#diplomacy">Diplomacy</a> (Cha),
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#hide">Hide</a> (Dex), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#jump">Jump</a> (Str), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#knowledge">Knowledge</a> (all skills, taken individually)
(Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#listen">Listen</a> (Wis), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#move-silently">Move Silently</a> (Dex), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#perform">Perform</a> (Cha),
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#profession">Profession</a> (Wis), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#spellcraft">Spellcraft</a> (Int), and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#swim">Swim</a> (Str).
    </p>
    <p>
      <b>Skill Points at Each Level:</b> 4 + Int modifier.
    </p>
    <h6><a id="gnome-paragon-class-features"></a>
Class Features
    </h6>
    <p>
All of the following are class features of the gnome paragon
class.
    </p>
    <p>
      <b>Weapon and Armor Proficiency:</b> Gnome paragons are proficient
with all simple weapons and with light armor.
    </p>
    <p>
      <b>Spells per Day:</b> At 2nd and 3rd level, a gnome paragon gains
new spells per day and spells known as if he had also gained a
level in <a style={{
        "color": "#579eb6"
      }} href="bard.html">bard</a>. He does not, however, gain any other benefit a
character of that class would have gained (bard class features,
and so on). This essentially means that he adds the level of gnome
paragon to his level in bard, then determines spells per day,
spells known, and caster level accordingly.
    </p>
    <p>
If a gnome paragon has no levels in bard, this class feature has
no effect.
    </p>
    <p>
      <a id="gnome-paragon-illusion-aptitude"></a><b>Illusion Aptitude (Ex):</b> A gnome paragon may add his class
level to his caster level to determine the effects of any illusion
spells he casts, including those gained as innate abilities of the
gnome race. This bonus stacks with the extra spellcasting levels
gained from the gnome paragon's spells per day entry (see above).
    </p>
    <p>
For example, a 2nd-level wizard/3rd-level gnome paragon's caster
level is 2nd for most wizard spells but 5th for wizard spells of
the illusion school (and 4th for his innate spell-like abilities
      <a style={{
        "color": "#579eb6"
      }} href="spellsDtoE.html#dancing-lights">dancing lights</a>, <a style={{
        "color": "#579eb6"
      }} href="spellsFtoG.html#ghost-sound">ghost sound</a>, and <a style={{
        "color": "#579eb6"
      }} href="spellsPtoR.html#prestidigitation">prestidigitation</a>). A 3rd-level
bard/2nd-level gnome paragon's caster level is 4th for most bard
spells but 6th for bard spells of the illusion school.
    </p>
    <p>
This increased caster level affects only spells that the character
can cast; it does not give him access to higher-level spells or
more spells per day.
    </p>
    <p>
      <a id="gnome-paragon-keen-senses"></a><b>Keen Senses (Ex):</b> A gnome paragon's racial bonus on <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#listen">Listen</a>
checks and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#craft">Craft</a> (alchemy) checks increases to +4.
    </p>
    <p>
      <a id="gnome-paragon-mirror-image"></a><b>Mirror Image (Sp):</b> At 2nd level, a gnome paragon gains the
ability to cast <a style={{
        "color": "#579eb6"
      }} href="spellsMtoO.html#mirror-image">mirror image</a> once per day as a spell-like ability
with a caster level equal to his gnome paragon level.
    </p>
    <p>
      <a id="gnome-paragon-ability-boost"></a><b>Ability Boost (Ex):</b> At 3rd level, a gnome paragon's
Charisma score increases by 2 points.
    </p>
    <h3><a id="half-dragon-paragon"></a>
HALF-DRAGON PARAGON
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/UnA_Gallery/79144.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a>
Half-dragons are a diverse lot. Their dragon parents differ in
attitudes and abilities depending on their kind, and the magical
nature of dragons means that their other parent can be nearly
anything. This diversity might seem to preclude the idea of a
half-dragon paragon, but the overwhelming strength of the dragon
heritage makes such paragons possible. Half-dragon paragons are
defined in large part by the nature of their dragon parent, making
a half-gold dragon much different from a half-red dragon � although
both are gifted with extraordinary physical abilities and force of
personality.
    <p>
      <a id="half-dragon-paragon-adventures"></a><b>Adventures:</b> Half-dragon paragons adventure for the obvious
reasons of gaining wealth, power, or the other trappings of
worldly success. Some are motivated to adventure to gain stature
in their dragon parents' eyes or (in the case of many chromatic
half-dragons) to gain the power necessary to protect themselves
from their powerful dragon parents. Metallic half-dragons might be
motivated to adventure to fulfill some noble quest or to undertake
some journey of selfdiscovery, but chromatic half-dragons
typically adventure for more selfish reasons.
    </p>
    <p>
      <a id="half-dragon-paragon-characteristics"></a><b>Characteristics:</b> Half-dragons have an innate
self-confidence that borders on arrogance � or crosses that border.
Many individuals, of course, manage to rein in their arrogance
around other races. Half-dragons follow the nature of their dragon
parents much more often than that of their other parent, being
kind or cruel as their dragon parent would be.
    </p>
    <p>
      <a id="half-dragon-paragon-alignment"></a><b>Alignment:</b> A half-dragon paragon generally follows the
precepts and beliefs of her dragon parent, adopting the alignment
and beliefs typical of that dragon parent's kind. There are those
who rebel against their dragon parents and their parents'
alignments, though. Instances of this behavior are more common
among chromatic half-dragon paragons � but the evil dragons are
often keen to destroy rebellious offspring. As a result,
half-dragon paragons with unexpected alignments are rare among
both metallic and chromatic varieties.
    </p>
    <p>
      <a id="half-dragon-paragon-religion"></a><b>Religion:</b> Half-dragon paragons rarely gravitate to
religion; they're simply too self-involved to study the tenets of
an organized religion. Many metallic half-dragons have no trouble
making common cause with good-aligned clerics, however, and those
who multiclass as paladins find that their noble natures blend
well with the religion behind the paladin's code. Chromatic
half-dragons, however, take a different tack, and many have
benefited from the adulation of cults of lesser creatures
dedicated to the worship of dragons. Whether they believe in their
own divinity or not, evil dragons and half-dragons have no trouble
duping and using those they see as beneath themselves.
    </p>
    <p>
      <a id="half-dragon-paragon-background"></a><b>Background:</b> Strife and tragedy haunts chromatic half-dragon
paragons just as love and a powerful protective presence shelters
most metallic half-dragon paragons. A half-dragon paragon masters
the skills of combat and adventuring out of necessity: Chromatic
half-dragons must do so to gain some measure of protection or
usefulness in the eyes of their powerful and cruel parents, and
metallic half-dragons must do so to ensure that they do not become
a vehicle through which others can take advantage of their noble
parents.
    </p>
    <p>
      <a id="half-dragon-paragon-races"></a><b>Races:</b> Just as it dominates many other aspects of their
lives, the innate confidence of half-dragon paragons shapes their
view of other races. Half-dragons have a grudging respect for any
who achieve greatness or prove themselves capable. But they
maintain, to one degree or another, that anything that one can
accomplish could be more easily accomplished if one were a
half-dragon.
    </p>
    <p>
      <a id="half-dragon-paragon-other-classes"></a><b>Other Classes:</b> Because half-dragon paragons are blessed
with substantial physical prowess, they tend to favor martial
classes for multiclass options, and they have a higher regard for
those who stand toe-to-toe with their enemies than for those who
strike from afar. Although they admit that spellcasters can indeed
grow mighty, they scoff at sorcerers, who owe their abilities to a
mere trace of dragon ancestry.
    </p>
    <h5><a id="half-dragon-paragon-game-rule-information"></a>
GAME RULE INFORMATION
    </h5>
    <b><a id="table-the-half-dragon-paragon"></a>Table: The Half-Dragon Paragon</b>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "80%"
    }}>
      <tbody>
        <tr>
          <th>
Level
          </th>
          <th>
Base
Attack
Bonus
          </th>
          <th>
Fort
Save
          </th>
          <th>
Ref
            <p>{`Save`}</p>
          </th>
          <th>
Will
Save
          </th>
          <th align="left">
Special
          </th>
        </tr>
        <tr className="odd-row">
          <td align="center">
1st
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+2
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#half-dragon-paragon-sorcerous-blood">Sorcerous blood</a>
          </td>
        </tr>
        <tr>
          <td align="center">
2nd
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+3
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#half-dragon-paragon-natural-armor-increase">Natural armor increase</a>
          </td>
        </tr>
        <tr className="odd-row">
          <td align="center">
3rd
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
+3
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#half-dragon-paragon-breath-weapon">Breath weapon</a> 3/day
          </td>
        </tr>
      </tbody>
    </table>
    <p>
Half-dragon paragons have the following game statistics.
    </p>
    <p>
      <b>Abilities:</b> Strength is important to half-dragon paragons
because of its role in combat. Charisma is important because many
half-dragon paragons also have minor sorcerous spellcasting
ability, and Wisdom and Charisma are both important for many
half-dragon paragon class skills.
    </p>
    <p>
      <b>Alignment:</b> Any.
    </p>
    <p>
      <b>Hit Die:</b> d12.
    </p>
    <h6><a id="half-dragon-paragon-class-skills"></a>
Class Skills
    </h6>
    <p>
The half-dragon paragon's class skills (and the key ability for
each skill) are <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#concentration">Concentration</a> (Con), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#diplomacy">Diplomacy</a> (Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#intimidate">Intimidate</a>
(Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#knowledge">Knowledge</a> (all skills, taken individually) (Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#listen">Listen</a>
(Wis), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#search">Search</a> (Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#sense-motive">Sense Motive</a> (Wis), and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#spot">Spot</a> (Wis).
    </p>
    <p>
In addition, each half-dragon paragon gains class skills based on
its parent dragon type:
    </p>
    <p>
      <b>Black:</b> <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#hide">Hide</a> (Dex), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#move-silently">Move Silently</a> (Dex), and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#swim">Swim</a>
(Str).
    </p>
    <p>
      <b>Blue:</b> <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#bluff">Bluff</a> (Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#hide">Hide</a> (Dex), and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#spellcraft">Spellcraft</a>
(Int).
    </p>
    <p>
      <b>Brass:</b> <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#bluff">Bluff</a> (Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#gather-information">Gather Information</a> (Cha), and
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#survival">Survival</a> (Wis).
    </p>
    <p>
      <b>Bronze:</b> <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#disguise">Disguise</a> (Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#survival">Survival</a> (Wis), and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#swim">Swim</a>
(Str).
    </p>
    <p>
      <b>Copper:</b> <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#bluff">Bluff</a> (Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#hide">Hide</a> (Dex), and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#jump">Jump</a> (Str).
    </p>
    <p>
      <b>Gold:</b> <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#disguise">Disguise</a> (Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#heal">Heal</a> (Wis), and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#swim">Swim</a> (Str).
    </p>
    <p>
      <b>Green:</b> <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#bluff">Bluff</a> (Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#hide">Hide</a> (Dex), and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#move-silently">Move Silently</a>
(Dex).
    </p>
    <p>
      <b>Red:</b> <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#appraise">Appraise</a> (Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#bluff">Bluff</a> (Cha), and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#jump">Jump</a> (Str).
    </p>
    <p>
      <b>Silver:</b> <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#bluff">Bluff</a> (Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#jump">Jump</a> (Str), and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#perform">Perform</a>
(Cha).
    </p>
    <p>
      <b>White:</b> <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#hide">Hide</a> (Dex), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#move-silently">Move Silently</a> (Dex), and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#swim">Swim</a>
(Str).
    </p>
    <p>
      <b>Skill Points at Each Level:</b> 4 + Int modifier.
    </p>
    <h6><a id="half-dragon-paragon-class-features"></a>
Class Features
    </h6>
    <p>
All of the following are class features of the half-dragon paragon
class.
    </p>
    <p>
      <b>Weapon and Armor Proficiency:</b> Half-dragon paragons gain no
proficiency with any weapons or armor.
    </p>
    <p>
      <a id="half-dragon-paragon-sorcerous-blood"></a><b>Sorcerous Blood (Ex):</b> A character's half-dragon paragon
levels stack with <a style={{
        "color": "#579eb6"
      }} href="sorcerer.html">sorcerer</a> levels for purposes of determining the
caster level of arcane spells granted through sorcerer levels. For
example, a 2nd-level sorcerer/3rd-level half-dragon paragon's
caster level is 5th for the spells she can cast as a sorcerer.
This increased caster level affects only spells that the character
can cast; it does not give her access to higher-level spells or
more spells per day.
    </p>
    <p>
      <a id="half-dragon-paragon-natural-armor-increase"></a><b>Natural Armor Increase (Ex):</b> At 2nd level, a half-dragon
paragon's natural armor bonus improves by 1.
    </p>
    <p>
      <a id="half-dragon-paragon-breath-weapon"></a><b>Breath Weapon (Su):</b> At 3rd level, a half-dragon paragon can
use her innate breath weapon three times per day (but only once
every 1d4 rounds).
    </p>
    <h3><a id="half-elf-paragon"></a>
HALF-ELF PARAGON
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/UnA_Gallery/79140.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a>
Although their mixed heritage rarely leaves half-elves embittered,
many can find it difficult to call any community home. Humans and
elves move through the world at very different paces and have
extremely divergent tastes and habits, and neither quite fits a
half-elf's innate preferences and attitudes. Halfelf paragons are
those half-elves who reconcile these two sides of their nature and
create an outlook that is wholly their own.
    <p>
      <a id="half-elf-paragon-adventures"></a><b>Adventures:</b> Half-elf paragons turn to adventuring for many
reasons. Like members of any other class, many half-elf paragons
adventure out of a simple desire for wealth, fame, or personal
power. Because they are less attached to their communities than
any other racial paragons (except perhaps for half-orcs), half-elf
paragons rarely adventure to defend a home or further the
interests of their race as a whole�instead, they often turn to
adventuring as a means of finding their own place in the world.
    </p>
    <p>
      <a id="half-elf-paragon-charactersistics"></a><b>Characteristics:</b> Half-elf paragons are a strange blend of
curiosity and introspection. Half-elf paragons share the adaptable
and inventive nature of their human parents with the refined
senses and penchant for long, careful thought of their elf
parents. Half-elf paragons are accomplished in both combat and
stealth, and one often fills the role of scout or sentry in an
adventuring party. Although it might seem to some that half-elf
paragons lack focus, it is in a half-elf paragon's nature to fit
her skills to the job at hand - and it is precisely her blend of
skills that makes her capable of overcoming a wide array of
challenges.
    </p>
    <p>
      <a id="half-elf-paragon-alignment"></a><b>Alignment:</b> Half-elf paragons can be of any alignment, but
most are chaotic and many are good. Freedom appeals to
half-elves � perhaps, because they seldom have firm roots in a
community, they find free choice and an open mind appealing.
Although a half-elf's mixed ancestry is occasionally the result of
an evil act, most half-elves exhibit the better aspects of their
parent races' natures.
    </p>
    <p>
      <a id="half-elf-paragon-religion"></a><b>Religion:</b> Half-elf paragons tend to have extreme views on
religion. Many shy away from it, seeking clerics and other
servants of the gods only when need arises. A few gravitate toward
religion, devoting themselves to a specific deity, an entire
pantheon, or a philosophy. Such individuals find, in religion, the
sense of belonging and purpose they don't get from their
communities. Half-elf paragons who are drawn to religion often
follow a deity of travel or freedom, finding the road and its
endless challenges a compelling change from their homes.
    </p>
    <p>
      <a id="half-elf-paragon-background"></a><b>Background:</b> The background of most half-elves, paragons
included, tends to be one of extremes. Either a half-elf finds
unusual acceptance and peace throughout her life, or she finds
herself faced with near-constant upheaval and turmoil. Whatever
their individual circumstances, adult half-elves rarely have
strong ties to a place or community, and many choose a life of
adventure and travel.
    </p>
    <p>
      <a id="half-elf-paragon-races"></a><b>Races:</b> Half-elf paragons are reserved in their views on
other races. They get along well with members of most races, but
the two races to which they are closest � humans and elves � are also
the two most likely to provoke strong feelings from a half-elf
paragon. Events in an individual half-elf paragon's life are
likely to shape attitudes about one or the other of those
races � usually for ill.
    </p>
    <p>
      <a id="half-elf-paragon-other-classes"></a><b>Other Classes:</b> Half-elf paragons are among the most open
minded adventurers to be found. They value the skills of other
classes equally, although their elven heritage gives them a slight
preference for wizards and rangers. Because half-elf paragons
often multiclass as <a style={{
        "color": "#579eb6"
      }} href="rogue.html">rogues</a> and <a style={{
        "color": "#579eb6"
      }} href="ranger.html">rangers</a>, they share some of the
predilections of those classes, valuing less subtle companions
when battle is joined but occasionally finding them a nuisance
when stealth is called for.
    </p>
    <h5><a id="half-elf-paragon-game-rule-information"></a>
GAME RULE INFORMATION
    </h5>
    <b><a id="talbe-the-half-elf-paragon"></a>Table: The Half-Elf Paragon</b>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "80%"
    }}>
      <tbody>
        <tr>
          <th>
Level
          </th>
          <th>
Base
Attack
Bonus
          </th>
          <th>
Fort
Save
          </th>
          <th>
Ref
Save
          </th>
          <th>
            <p>{`Will
Save`}</p>
          </th>
          <th align="left">
Special
          </th>
          <th align="left">
Spells per Day
          </th>
        </tr>
        <tr className="odd-row">
          <td align="center">
1st
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+0
          </td>
          <td>
Bonus feat,
            <a style={{
              "color": "#579eb6"
            }} href="#half-elf-paragon-divided-ancestry">divided ancestry</a>,
            <a style={{
              "color": "#579eb6"
            }} href="#half-elf-paragon-elven-vision">elven vision</a>
          </td>
          <td>
&mdash;
          </td>
        </tr>
        <tr>
          <td align="center">
2nd
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+0
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#half-elf-paragon-persuasion">Persuasion</a>
          </td>
          <td>
+1 level of existing class
          </td>
        </tr>
        <tr className="odd-row">
          <td align="center">
3rd
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+1
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#half-elf-paragon-ability-boost">Ability boost</a> (+2)
          </td>
          <td>
�
          </td>
        </tr>
      </tbody>
    </table>
    <p>
Half-elf paragons have the following game statistics.
    </p>
    <p>
      <b>Abilities:</b> Equally adept at melee and ranged combat,
half-elf paragons benefit from high Strength and high Dexterity
scores. Wisdom and Charisma, because of their importance to the
half-elf paragon's most useful class skills, are also valuable to
the class.
    </p>
    <p>
      <b>Alignment:</b> Any.
    </p>
    <p>
      <b>Hit Die:</b> d8.
    </p>
    <h6><a id="half-elf-paragon-class-skills"></a>
Class Skills
    </h6>
    <p>
The half-elf paragon's class skills (and the key ability for each
skill) are <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#bluff">Bluff</a> (Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#climb">Climb</a> (Str), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#craft">Craft</a> (Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#diplomacy">Diplomacy</a> (Cha),
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#hide">Hide</a> (Dex), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#jump">Jump</a> (Str), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#listen">Listen</a> (Wis), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#move-silently">Move Silently</a> (Dex), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#perform">Perform</a>
(Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#profession">Profession</a> (Wis), and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#swim">Swim</a> (Str).
    </p>
    <p>
      <b>Skill Points at Each Level:</b> 4 + Int modifier.
    </p>
    <h6><a id="half-elf-paragon-class-features"></a>
Class Features
    </h6>
    <p>
All of the following are class features of the half-elf paragon
class.
    </p>
    <p>
      <b>Weapon and Armor Proficiency:</b> Half-elf paragons are
proficient with all simple weapons and with light armor.
    </p>
    <p>
      <b>Spells per Day:</b> At 2nd level, a half-elf paragon gains new
spells per day (and spells known, if applicable) as if she had
also gained a level in a spellcasting class she belonged to before
adding the level. She does not, however, gain any other benefit a
character of that class would have gained (bonus metamagic or item
creation feats, bard or assassin abilities, and so on). This
essentially means that she adds the level of half-elf paragon to
the level in the spellcasting class, then determines spells per
day, spells known, and caster level accordingly.
    </p>
    <p>
If a character had more than one spellcasting class before she
became a half-elf paragon, she must decide to which class she adds
the level of half-elf paragon. If a half-elf paragon has no levels
in a spellcasting class, this class feature has no effect.
    </p>
    <p>
      <b>Bonus Feat (Ex):</b> At 1st level, a half-elf paragon gains a
bonus feat. The character must meet the feat's prerequisites
normally.
    </p>
    <p>
      <a id="half-elf-paragon-dvided-ancenstry"></a><b>Divided Ancestry (Ex):</b> Unlike other racial paragons,
half-elves can take levels in more than one racial paragon class.
After gaining at least one level as a half-elf paragon, a
character can take either <a style={{
        "color": "#579eb6"
      }} href="#elf-paragon">elf paragon</a> levels or <a style={{
        "color": "#579eb6"
      }} href="#human-paragon">human paragon</a>
levels (but not both).
    </p>
    <p>
      <a id="half-elf-paragon-elven-vision"></a><b>Elven Vision (Ex):</b> At 1st level, a half-elf paragon's
visual acuity improves to match that of an elf's. A half-elf
paragon's racial bonus on <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#search">Search</a> and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#spot">Spot</a> checks increases to +2.
    </p>
    <p>
      <a id="half-elf-paragon-persuasion"></a><b>Persuasion (Ex):</b> A 2nd level half-elf paragon's racial
bonus on <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#siplomacy">Diplomacy</a> and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#gather-information">Gather information</a> checks increases to +3.
She gains a +2 racial bonus on all other <a style={{
        "color": "#579eb6"
      }} href="skillsCha.html">Charisma-based skills</a>.
    </p>
    <p>
      <a id="half-elf-paragon-ability-boost"></a><b>Ability Boost (Ex):</b> At 3rd level, a half-elf paragon
increases one ability score of her choice by 2 points.
    </p>
    <h3><a id="half-orc-paragon"></a>
HALF-ORC PARAGON
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/UnA_Gallery/79140.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a>
Half-orc paragons differ from other racial paragons in that they
rarely have large populations of other half-orcs promoting and
supporting their views of their race. Rather, most half-orc
paragons find the strength to champion their heritage by
overcoming the challenges of a unique and sometimes isolating
background. Powerful warriors, half-orc paragons combine the
innate ferocity of their orc heritage with the quick thinking and
adaptability of humanity.
    <p>
      <a id="half-orc-paragon-adventures"></a><b>Adventures:</b> Half-orc paragons adventure to see the world,
look for a home, search for fame and power, or simply wander for
wandering's sake. With fewer connections to their race as a whole
than other racial paragons, half-orc paragons are likely to remain
active adventurers for long periods of time.
    </p>
    <p>
Successful half-orc paragon adventurers often attract others of
their race. These like-minded half-orcs seek acceptance in the
half-orc paragon's community, seek to become adventuring
companions, or simply want to be followers.
    </p>
    <p>
      <a id="half-orc-paragon-characteristics"></a><b>Characteristics:</b> Half-orc paragons are as mixed in outlook
as they are in ancestry. Most half-orc paragons come to identify
strongly with one or the other of their parent races, and see
themselves as the bridge between that race and other half-orcs.
Many view themselves as gifted with strength and opportunities
beyond those of either of their parent races.
    </p>
    <p>
      <a id="half-orc-paragon-alignment"></a><b>Alignment:</b> Half-orc paragons, like most orcs and half-orcs,
tend toward a chaotic outlook. Some half-orc paragons give in to
bitterness caused by the difficulties they encounter in
communities of other races, but most look beyond that, drawing
strength of character from the inequities they face.
    </p>
    <p>
      <a id="half-orc-paragon-religion"></a><b>Religion:</b> Half-orc paragons rarely have strong religious
views. Although they respect the obvious power of the deities and
their clerics, few half-orc paragons feel the call of a higher
power � perhaps because they sit so clearly between two numerous and
powerful races.
    </p>
    <p>
      <a id="half-orc-paragon-background"></a><b>Background:</b> Most half-orc paragons become champions of
their race because they endure unusual circumstances involving
both humans and orcs. Many who are born in areas of great conflict
between the two races feel the constant pull of a mixed heritage.
Often, it is travel away from such areas that crystallizes the
paragon's view of his own race, allowing him to see the value and
virtue in his own abilities and outlook.
    </p>
    <p>
      <a id="half-orc-paragon-races"></a><b>Races:</b> Half-orc paragons face challenges wherever they
turn, for many races look on them with suspicion or view their
orcish parentage as a taint. Most half-orc paragons form close
friendships with only a few individuals. Because it is usually
difficult for a half-orc to find friendship and trust, even among
those of their own kind, half-orc paragons are all the more loyal
to those who do become their close friends.
    </p>
    <p>
      <a id="half-orc-paragon-other-classes"></a><b>Other Classes:</b> Half-orc paragons understand the harsh life
and ferocity of <a style={{
        "color": "#579eb6"
      }} href="barbarian.html">barbarians</a>, particularly those paragons who hail
from regions populated by barbarian tribes. Half-orc paragons
admire classes that are proficient in battle, considering the
practiced skills of a <a style={{
        "color": "#579eb6"
      }} href="fighter.html">fighter</a> and the woods lore of the <a style={{
        "color": "#579eb6"
      }} href="ranger.html">ranger</a>
equally valid choices for a warrior. Although half-orc paragons
can admire the effects of powerful spells wielded by <a style={{
        "color": "#579eb6"
      }} href="cleric.html">clerics</a>,
      <a style={{
        "color": "#579eb6"
      }} href="wizard.html">wizards</a>, and other spellcasters, they often look on members of
such classes with suspicion or even contempt, seeing magic as
little more than a cover for physical weakness.
    </p>
    <h5><a id="half-orc-paragon-game-rule-information"></a>
GAME RULE INFORMATION
    </h5>
    <b><a id="table-the-half-orc-paragon"></a>Table: The Half-Orc Paragon</b>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "80%"
    }}>
      <tbody>
        <tr>
          <th>
Level
          </th>
          <th>
Base
Attack
Bonus
          </th>
          <th>
Fort
Save
          </th>
          <th>
Ref
Save
          </th>
          <th>
Will
Save
          </th>
          <th align="left">
Special
          </th>
        </tr>
        <tr className="odd-row">
          <td align="center">
1st
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+0
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#half-orc-paragon-divided-ancestry">Divided ancestry</a>, <a style={{
              "color": "#579eb6"
            }} href="#half-orc-paragon-monstrous-mien">monstrous mien</a>
          </td>
        </tr>
        <tr>
          <td align="center">
2nd
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+0
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#half-orc-paragon-rage">Rage</a> 1/day
          </td>
        </tr>
        <tr className="odd-row">
          <td align="center">
3rd
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
+1
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#half-orc-paragon-ability-boost">Ability boost</a> (Str +2)
          </td>
        </tr>
      </tbody>
    </table>
    <p>
Half-orc paragons have the following game statistics.
    </p>
    <p>
      <b>Abilities:</b> Strength is important to half-orc paragons
because of its role in combat, and several half-orc paragon skills
are based on Strength. Dexterity and Constitution are valued
because of their roles in combat, and a high Constitution also
allows a half-orc paragon to rage for longer periods of time.
    </p>
    <p>
      <b>Alignment:</b> Any, although predominantly chaotic.
    </p>
    <p>
      <b>Hit Die:</b> d8.
    </p>
    <h6><a id="half-orc-paragon-class-skills"></a>
Class Skills
    </h6>
    <p>
The half-orc paragon's class skills (and the key ability for each
skill) are <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#climb">Climb</a> (Str), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#craft">Craft</a> (Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#handle-animal">Handle Animal</a> (Cha),
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#intimidate">Intimidate</a> (Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#jump">Jump</a> (Str), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#profession">Profession</a> (Wis), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#ride">Ride</a> (Dex),
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#survival">Survival</a> (Wis), and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#swim">Swim</a> (Str).
    </p>
    <p>
      <b>Skill Points at Each Level:</b> 4 + Int modifier.
    </p>
    <h6><a id="half-orc-paragon-class-features"></a>
Class Features
    </h6>
    <p>
All of the following are class features of the half-orc paragon
class.
    </p>
    <p>
      <b>Weapon and Armor Proficiency:</b> Half-orc paragons are
proficient with all simple and martial weapons, and with light
armor and medium armor.
    </p>
    <p>
      <a id="half-orc-paragon-dividen-ancestry"></a><b>Divided Ancestry (Ex):</b> Unlike other racial paragons,
half-orcs can take levels in more than one racial paragon class.
After gaining at least one level as a half-orc paragon, a
character can take either <a style={{
        "color": "#579eb6"
      }} href="#orc-paragon">orc paragon</a> levels or <a style={{
        "color": "#579eb6"
      }} href="#human-paragon">human paragon</a>
levels (but not both).
    </p>
    <p>
      <a id="half-orc-paragon-monstrous-mien"></a><b>Monstrous Mien (Ex):</b> Half-orc paragons can channel their
innate ferocity, resulting in a +4 racial bonus on <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#intimidate">Intimidate</a>
checks.
    </p>
    <p>
      <a id="half-orc-paragon-rage"></a><b>Rage (Ex):</b> At 2nd level, a half-orc paragon comes to
understand and draw upon the natural ferocity that courses through
his blood. He gains the ability to fly into a screaming blood
frenzy once per day (or one additional time per day, if he already
has a <a style={{
        "color": "#579eb6"
      }} href="barbarian.html#barbarian-rage">rage</a> ability). The effect of this ability is identical with
the effect of a barbarian's rage ability. If a half-orc paragon
has improved rage abilities (such as the barbarian class features
      <a style={{
        "color": "#579eb6"
      }} href="barbarian.html#barbarian-greater-rage">greater rage</a>, <a style={{
        "color": "#579eb6"
      }} href="barbarian.html#barbarian-indomitable-will">indomitable will</a>, <a style={{
        "color": "#579eb6"
      }} href="barbarian.html#barbarian-tireless-rage">tireless rage</a>, or <a style={{
        "color": "#579eb6"
      }} href="barbarian.html#barbarian-mighty-rage">mighty rage</a>),
those improvements apply to the rage ability granted by the
half-orc paragon class as well.
    </p>
    <p>
      <a id="half-orc-paragon-ability-boost"></a><b>Ability Boost (Ex):</b> At 3rd level, a half-orc paragon's
Strength score increases by 2 points.
    </p>
    <h3><a id="halfling-paragon"></a>
HALFLING PARAGON
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/UnA_Gallery/79142.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a>
Clever and capable, halfling paragons love travel, and they embody
the curiosity and easygoing ways of the halfling people. Despite
their curiosity and good-natured outlook, halfling paragons are
the most capable members of their race. They are survivors,
cunning and resourceful enough to survive in a world filled with
large, dangerous creatures. When halfling paragons travel through
communities and countries populated by other races, they are quick
to make friends and often end up at the center of traveling or
adventuring groups.
    <p>
      <a id="halfling-paragon-adventures"></a><b>Adventures:</b> Filled with the wanderlust of the halfling
race, halfling paragons see adventuring as a natural extension of
their travels through the world. In fact, it's unlikely that a
halfling paragon thinks of herself as being on an adventure at any
time, even in the middle of a trapfilled dungeon. For a character
with a lust for excitement and discovery, what could be a more
natural place to visit than a dungeon or ancient ruin?
    </p>
    <p>
Occasionally halfling paragons embody the worst of their race's
characteristics, using their stealth abilities and their natural
cunning to steal from other races � races they consider dupes and
fools.
    </p>
    <p>
      <a id="halfling-paragon-characteristics"></a><b>Characteristics:</b> Exasperated adventuring companions claim
that halfling paragons invented curiosity. With their small size
and uncanny stealth, halfling paragons can go nearly anywhere
undetected. Remaining undetected, however, is rarely any fun.
halfling paragons enjoy the benefits of their small size, and many
secretly wonder how bigger races can ever do anything quietly.
    </p>
    <p>
      <a id="halfling-paragon-alignment"></a><b>Alignment:</b> Most halfling paragons are chaotic good. They
love freedom, and their approach to life is in many ways carefree
and unpredictable. While most halflings tend toward neutrality
over chaos, a halfling paragon embodies the race's curiosity and
love of travel.
    </p>
    <p>
      <a id="halfling-paragon-religion"></a><b>Religion:</b> Halfling paragons favor a variety of deities.
Some flit from religion to religion carelessly; they find new
ideas compelling and often investigate the tenets of one religion
for a while before moving on to another. In this way, halfling
paragons amass a few scattered bits of philosophy culled from
several different religions into their own happy amalgam. Halfling
paragons who remain dedicated to one deity or philosophy almost
always multiclass as <a style={{
        "color": "#579eb6"
      }} href="cleric.html">clerics</a> devoted to a god of travel.
    </p>
    <p>
      <a id="halfling-paragon-background"></a><b>Background:</b> A halfling paragon learns her skills while
traveling with her clan. Although she may be born to a nomadic
people, even the clan's travel eventually seems too infrequent,
the pace too slow. The wanderlust of the halfling race flows
through her, and eventually she sets off on her own, outpacing the
slow-moving wagons of her people. Many halfling paragons
multiclass as <a style={{
        "color": "#579eb6"
      }} href="rogue.html">rogues</a> or <a style={{
        "color": "#579eb6"
      }} href="ranger.html">rangers</a>, and their skills develop while
sneaking around in large cities or stalking game through deep
wilderness.
    </p>
    <p>
      <a id="halfling-paragon-races"></a><b>Races:</b> Halfling paragons travel, and they never seem to
tire out. Because they spend so much time in the communities of
other races, they strive to get along well with everyone. They are
adept, as most halflings are, at fitting into the communities of
others, although they rarely stay long.
    </p>
    <p>
Halfling paragons enjoy a mixed lot of traveling companions � too
many members of just one race makes for boring company. Although
open-minded in the extreme, halfling paragons can occasionally
find dwarves too dour and serious for their tastes. halfling
paragons particularly enjoy the company of gnomes.
    </p>
    <p>
      <a id="halfling-paragon-other-classes"></a><b>Other Classes:</b> Halfling paragons regard other classes with
a mixture of relief and frustration. They are happy to stand
behind <a style={{
        "color": "#579eb6"
      }} href="fighter.html">fighters</a> and members of other physically powerful classes
when monsters charge up to do battle, and they are grateful when a
spellcaster's abilities help them sneak past an opponent or heal
their wounds. But few classes can match the halfling paragon's
capability for stealth, and more often than not it is members of
those other classes that get the halfling paragon into a fight in
the first place. Halfling paragons get along best with other
stealthy characters, particularly <a style={{
        "color": "#579eb6"
      }} href="rogue.html">rogues</a> and <a style={{
        "color": "#579eb6"
      }} href="ranger.html">rangers</a>, and many
multiclass between the two classes.
    </p>
    <h5><a id="halfling-paragon-game-rule-information"></a>
GAME RULE INFORMATION
    </h5>
    <b><a id="table-the-halfling-paragon"></a>Table: The Halfling Paragon</b>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "80%"
    }}>
      <tbody>
        <tr>
          <th>
Level
          </th>
          <th>
Base
Attack
Bonus
          </th>
          <th>
Fort
Save
          </th>
          <th>
Ref
Save
          </th>
          <th>
Will
Save
          </th>
          <th align="left">
Special
          </th>
        </tr>
        <tr className="odd-row">
          <td align="center">
1st
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+0
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#halfling-paragon-athletic-prowess">Athletic prowess</a>, <a style={{
              "color": "#579eb6"
            }} href="#halfling-paragon-save-bonus">save bonus</a>
          </td>
        </tr>
        <tr>
          <td align="center">
2nd
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+0
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#halfling-paragon-thrown-weapon-mastery">Thrown weapon mastery</a>
          </td>
        </tr>
        <tr className="odd-row">
          <td align="center">
3rd
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+1
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#halfling-paragon-ability-boost">Ability boost</a> (Dex +2)
          </td>
        </tr>
      </tbody>
    </table>
    <p>
Halfling paragons have the following game statistics.
    </p>
    <p>
      <b>Abilities:</b> Dexterity is important to halfling paragons
because it helps them avoid the attacks of larger foes and
increases their accuracy with thrown weapons and slings, their
favorite forms of attack. Because halfling paragons value skill
and subtlety, a high Intelligence can help them a great deal.
Charisma is important to many of the halfling paragon's class
skills.
    </p>
    <p>
      <b>Alignment:</b> Any, although most favor neutrality.
    </p>
    <p>
      <b>Hit Die:</b> d6.
    </p>
    <h6><a id="halfling-paragon-class-skills"></a>
Class Skills
    </h6>
    <p>
The halfling paragon's class skills (and the key ability for each
skill) are <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#bluff">Bluff</a> (Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#climb">Climb</a> (Str), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#craft">Craft</a> (Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#diplomacy">Diplomacy</a> (Cha),
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#hide">Hide</a> (Dex), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#jump">Jump</a> (Str), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#listen">Listen</a> (Wis), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#move-silently">Move Silently</a> (Dex), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#perform">Perform</a>
(Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#profession">Profession</a> (Wis), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#survival">Survival</a> (Wis), and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#swim">Swim</a> (Str).
    </p>
    <p>
      <b>Skill Points at Each Level:</b> 4 + Int modifier.
    </p>
    <h6><a id="halfling-paragon-class-features"></a>
Class Features
    </h6>
    <p>
All of the following are class features of the halfling paragon
class.
    </p>
    <p>
      <b>Weapon and Armor Proficiency:</b> Halfling paragons are
proficient with all simple weapons and with light armor.
    </p>
    <p>
      <a id="halfling-paragon-athletic-prowess"></a><b>Athletic Prowess (Ex):</b> At 1st level, a halfling paragon's
racial bonus on <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#climb">Climb</a>, <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#jump">Jump</a>, and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#move-silently">Move Silently</a> checks improves to
+3.
    </p>
    <p>
      <a id="halfling-paragon-save-bonus"></a><b>Save Bonus (Ex):</b> A 1st-level halfling paragon's racial
bonus on all saving throws increases by 1.
    </p>
    <p>
      <a id="halfling-paragon-thrown-weapon-mastery"></a><b>Thrown Weapon Mastery (Ex):</b> At 2nd level, a halfling
paragon gains a +2 bonus on damage rolls when using a thrown
weapon or a sling.
    </p>
    <p>
      <a id="halfling-paragon-ability-boost"></a><b>Ability Boost (Ex):</b> At 3rd level, a halfling paragon's
Dexterity score increases by 2 points.
    </p>
    <h3><a id="human-paragon"></a>
HUMAN PARAGON
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/UnA_Gallery/79140.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a>
Human inventors, explorers, and others who push the boundaries of
knowledge and experience may become human paragons. They turn
adversity into opportunity by finding new ways to apply their
skills, discovering new techniques to solve problems, and
challenging entropy by constantly seeking out ventures they have
not yet mastered. Humans, and the paragons who rise from among
them, balance strength with agility and temper intellect with
intuition.
    <p>
      <a id="human-paragon-adventures"></a><b>Adventures:</b> Adventure occupies much of a human paragon's
time. Whether impulsive or carefully studious, a human paragon
searches almost constantly for the new and unexplored. Few,
however, think of themselves as pioneers or inventors. They see
themselves as naturally curious, deeming the intelligent and
unusual way in which they use their skills to be the norm rather
than an exception. Curiosity and wanderlust are easily the two
most common motivations for undertaking adventures, but human
paragons may find themselves on adventures as a result of a quest
or some other necessary duty.
    </p>
    <p>
      <a id="human-paragon-characteristics"></a><b>Characteristics:</b> Human paragons are inventiveness
personified. They are adaptable and capable of using their skills
in a broad variety of situations. Perhaps because they are capable
in such a wide range of situations, human paragons tend to dislike
repetition and crave variety. They seldom pass up a chance to
indulge in a new experience, and a new challenge is much more
likely to excite them than a lingering goal.
    </p>
    <p>
If human paragons have a downfall, it is that they see themselves
capable of overcoming any challenge or inventing a solution for
any problem. When asked who would be best for a task, a human
paragon almost always chooses himself � not out of overconfidence or
egotism, but because he has learned through success after success
that when backed into a corner, he'll almost always find a way
out.
    </p>
    <p>
      <a id="human-paragon-alignment"></a><b>Alignment:</b> Human paragons have few tendencies in alignment.
Perhaps more are attracted to chaos over law, although those who
become devoted to a religion or cause seem to gravitate toward
lawful deities or philosophies.
    </p>
    <p>
      <a id="human-paragon-religion"></a><b>Religion:</b> Humans are as varied in their beliefs as they are
in every other aspect of their lives, and human paragons are no
exception. Many human paragons dabble in religion, learning a bit
of one doctrine, following another deity for a brief time, and so
on. Although these individuals seldom stay with one belief system
for long, they become quite well versed in religion over time.
While it's more common to find human paragons with only these
surface attachments to religion, some human paragons develop a
passion for worship that fuses seamlessly with their views of
humanity, resulting in zealotry. In almost every case, these human
paragons follow lawful deities and multiclass as <a style={{
        "color": "#579eb6"
      }} href="cleric.html">clerics</a> or
      <a style={{
        "color": "#579eb6"
      }} href="paladin.html">paladins</a>.
    </p>
    <p>
      <a id="human-paragon-background"></a><b>Background:</b> Although there is no single common background
for human paragons, many share some common characteristics. They
often arise from situations in which they have many opportunities
to learn, either from parents, an accepting community, or even a
monastery filled with learned <a style={{
        "color": "#579eb6"
      }} href="monk.html">monks</a> and <a style={{
        "color": "#579eb6"
      }} href="cleric.html">clerics</a>.
    </p>
    <p>
      <a id="human-paragon-races"></a><b>Races:</b> Human paragons are open and accepting, embodying the
best aspects of humanity's flexibility and tolerance. Human
paragons usually delight in poking gentle fun at the foibles of
other races, finding their extremes of behavior amusing. They also
find much to admire in other races, including the stoic fortitude
of the dwarves, the beauty and grace of the elves, and the
curiosity and inventiveness of the halflings. In truth, human
paragons are closer in attitude to halflings than any other
race � both suffer (or benefit) from more than a touch of
wanderlust.
    </p>
    <p>
      <a id="human-paragon-other-classes"></a><b>Other Classes:</b> Human paragons are widely accepting of other
classes. They value the presence of <a style={{
        "color": "#579eb6"
      }} href="fighter.html">fighters</a>, <a style={{
        "color": "#579eb6"
      }} href="ranger.html">rangers</a>, and other
skilled combatants, knowing that they are more likely to survive a
battle when teamed with such characters. They also understand the
value of knowledge, and they regard spellcasters with a bit of
awe. <a style={{
        "color": "#579eb6"
      }} href="rogue.html">Rogues</a> and <a style={{
        "color": "#579eb6"
      }} href="bard.html">bards</a>, closest in ability (if not in spirit) to
human paragons, usually have skills that overlap the paragon's in
several areas, allowing the paragon to diversify his learning
rather than become overly specialized.
    </p>
    <h5><a id="human-paragon-game-rule-information"></a>
GAME RULE INFORMATION
    </h5>
    <b><a id="table-the-human-paragon"></a>Table: The Human Paragon</b>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "80%"
    }}>
      <tbody>
        <tr>
          <th>
Level
          </th>
          <th>
Base
Attack
Bonus
          </th>
          <th>
Fort
Save
          </th>
          <th>
Ref
Save
          </th>
          <th>
            <p>{`Will
Save`}</p>
          </th>
          <th align="left">
Special
          </th>
          <th align="left">
Spells per Day
          </th>
        </tr>
        <tr className="odd-row">
          <td align="center">
1st
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+2
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#human-paragon-adaptive-learning">Adaptive learning</a>
          </td>
          <td>
&mdash;
          </td>
        </tr>
        <tr>
          <td align="center">
2nd
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+3
          </td>
          <td>
Bonus feat
          </td>
          <td>
+1 level of existing class
          </td>
        </tr>
        <tr className="odd-row">
          <td align="center">
3rd
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
+3
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#human-paragon-ability-boost">Ability boost</a> (+2)
          </td>
          <td>
+1 level of existing class
          </td>
        </tr>
      </tbody>
    </table>
    <p>
Human paragons have the following game statistics.
    </p>
    <p>
      <b>Abilities:</b> Skilled and quick to adapt, human paragons
benefit from a high Intelligence. But they are also often at the
forefront of humanity's wars, making Strength, Dexterity, and
Constitution important for their respective roles in combat.
    </p>
    <p>
      <b>Alignment:</b> Any.
    </p>
    <p>
      <b>Hit Die:</b> d8.
    </p>
    <h6><a id="human-paragon-class-skills"></a>
Class Skills
    </h6>
    <p>
The human paragon can choose any ten <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html">skills</a> as class skills.
(<a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#knowledge">Knowledge</a> skills must be selected individually.)
    </p>
    <p>
      <b>Skill Points at Each Level:</b> 4 + Int modifier. (Does not
include the human's bonus skill point at each level.)
    </p>
    <h6><a id="human-paragon-class-features"></a>
Class Features
    </h6>
    <p>
All of the following are class features of the human paragon.
    </p>
    <p>
      <b>Weapon and Armor Proficiency:</b> Human paragons are proficient
with all simple weapons and with light armor. In addition, a human
paragon is proficient with any one martial weapon of his choice.
    </p>
    <p>
      <b>Spells per Day:</b> At 2nd and 3rd level, a human paragon gains
new spells per day (and spells known, if applicable) as if he had
also gained a level in a spellcasting class he belonged to before
adding the level. He does not, however, gain any other benefit a
character of that class would have gained (bonus metamagic or item
creation feats, bard or assassin abilities, and so on). This
essentially means that he adds the level of human paragon to the
level in the spellcasting class, then determines spells per day,
spells known, and caster level accordingly.
    </p>
    <p>
If a character had more than one spellcasting class before he
became a human paragon, he must decide to which class he adds each
level of human paragon. If a human paragon has no levels in a
spellcasting class, this class feature has no effect.
    </p>
    <p>
      <a id="human-paragon-adaptive-learning"></a><b>Adaptive Learning (Ex):</b> At 1st level, a human paragon can
designate any one of his human paragon class skills as an adaptive
skill. This skill is treated as a class skill in all respects for
all classes that character has levels in, both current and future.
For example, if a human paragon chooses <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#spot">Spot</a> as an adaptive skill,
he treats Spot as a class skill for all future class levels he
gains, even if it is not normally a class skill for the class in
question.
    </p>
    <p>
      <b>Bonus Feat (Ex):</b> At 2nd level, a human paragon gains a
bonus feat. Due to the varied talents and adaptable nature of
humans, this feat can be any feat for which the human paragon is
qualified (he is not restricted to a special list of bonus feats).
The character must meet the prerequisites for the bonus feat
normally.
    </p>
    <p>
      <a id="human-paragon-ability-boost"></a><b>Ability Boost (Ex):</b> At 3rd level, a human paragon increases
one ability score of his choice by 2 points.
    </p>
    <h3><a id="orc-paragon"></a>
ORC PARAGON
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/UnA_Gallery/79143.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a>
Aggressive, brutal, and often at odds with the other humanoid
races, orcs view war and hatred as their right. They have a deep
and nearly endless enmity for elves and dwarves, having battled
both races for countless generations. Like members of other
paragon classes, orc paragons embody the best of their race,
combining the strength and confidence of their people with
intelligent observation and a willingness to temper risk with
calculation.
    <p>
      <a id="orc-paragon-adventures"></a><b>Adventures:</b> Orcs undertake adventures for many reasons,
although most do so to gain wealth or power. For orcs,
"adventuring" often means raiding the cities and towns of other
humanoid races, rather than exploring lost tombs or slaying
powerful monsters. Despite this tendency, orcs can occasionally be
found accompanying small groups of adventurers on quests of great
significance. Some find their own culture stifling, and wish to
rise above its cruel savagery, while others seek to learn the
fighting techniques of other races to grow mighty themselves,
hoping to become a champion of the orc race.
    </p>
    <p>
      <a id="orc-paragon-characteristics"></a><b>Characteristics:</b> Mean and foul-tempered, orcs have many
characteristics that other humanoid races find offensive or
repulsive. Most orcs are savage and cruel. Orc paragons, however,
ternper this behavior with keener powers of observation and a
slightly more tolerant view of other races and cultures. Orc
paragons are much more curious than other members of their race,
and many come to see learning as the key to advancing the orc
cause.
    </p>
    <p>
      <a id="orc-paragon-alignment"></a><b>Alignment:</b> Orc paragons are nearly always chaotic, but a
rare few see a greater future for orcs as a race if they can
become more orderly and organized. These orc paragons, arguably
the most forward-thinking members of their warlike race, favor
neutral alignments.
    </p>
    <p>
      <a id="orc-paragon-religion"></a><b>Religion:</b> Although orcs as a race tend to follow the
teachings of brutal and savage deities or worship powerful evil
creatures, orc paragons are seldom deeply religious. Those who
rise to leadership positions in their tribes don't hesitate to use
religious beliefs as a tool to shape the tribe members to their
desired ends, but they rarely possess strong beliefs themselves.
The exceptions to this general statement can be extremely
dangerous, however, and sometimes raise great hosts in the name of
some powerful orc god.
    </p>
    <p>
      <a id="orc-paragon-background"></a><b>Background:</b> Orc paragons gain their skills in the crucible
that is orc culture. They prove their strength by defeating lesser
members of their tribes and often earn leadership positions within
their culture. Nearly all orc paragons eventually seek knowledge
and skills from cultures beyond their own, striving to bring more
potent forms of combat arid more sophisticated knowledge back to
their tribes.
    </p>
    <p>
      <a id="orc-paragon-races"></a><b>Races:</b> Orc paragons, like other orcs, hate and detest elves
and dwarves above all other races. Orcs rarely view other races as
anything more than potential prey for their raids and wars, but
when necessary, orc paragons can suppress their aggressive nature
and get along with other races.
    </p>
    <p>
      <a id="orc-paragon-other-classes"></a><b>Other Classes:</b> Orc paragons respect those with strength and
physical power, especially <a style={{
        "color": "#579eb6"
      }} href="barbarian.html">barbarians</a> and <a style={{
        "color": "#579eb6"
      }} href="fighter.html">fighters</a>. <a style={{
        "color": "#579eb6"
      }} href="ranger.html">Rangers</a> are
more often opponents of orc paragons than allies, but even so the
two classes share a grudging respect for each other's skills and
martial proficiency. Orc paragons care little for arcane
spellcasters, but quickly learn to value powerful <a style={{
        "color": "#579eb6"
      }} href="wizard.html">wizards</a> and
      <a style={{
        "color": "#579eb6"
      }} href="sorcerer.html">sorcerers</a> capable of taking down multiple foes with a single
spell.
    </p>
    <h5><a id="orc-paragon-game-rule-information"></a>
GAME RULE INFORMATION
    </h5>
    <b><a id="table-the-orc-paragon"></a>Table: The Orc Paragon</b>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "50%"
    }}>
      <tbody>
        <tr>
          <th>
Level
          </th>
          <th>
Base
Attack
Bonus
          </th>
          <th>
Fort
Save
          </th>
          <th>
Ref
Save
          </th>
          <th>
Will
Save
          </th>
          <th align="left">
Special
          </th>
        </tr>
        <tr className="odd-row">
          <td align="center">
1st
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+0
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#half-orc-paragon-improved-darkvision">Improved darkvision</a> (+30 ft.), <a style={{
              "color": "#579eb6"
            }} href="#half-orc-paragon-no-light-sensitivity">no light sensitivity</a>
          </td>
        </tr>
        <tr>
          <td align="center">
2nd
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+0
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#half-orc-paragon-elf-slayer">Elf slayer</a>
          </td>
        </tr>
        <tr className="odd-row">
          <td align="center">
3rd
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
+1
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#half-orc-paragon-ability-boost">Ability boost</a> (Str +2)
          </td>
        </tr>
      </tbody>
    </table>
    <p>
Orc paragons have the following game statistics.
    </p>
    <p>
      <b>Abilities:</b> Strength is important to orc paragons because of
its role in combat, and several orc paragon skills are based on
Strength. Dexterity and Constitution are also important to an orc
paragon's capability for combat.
    </p>
    <p>
      <b>Alignment:</b> Any.
    </p>
    <p>
      <b>Hit Die:</b> d10.
    </p>
    <h6><a id="orc-paragon-class-skills"></a>
Class Skills
    </h6>
    <p>
The orc paragon's class skills (and the key ability for each
skill) are <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#climb">Climb</a> (Str), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#craft">Craft</a> (Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#intimidate">Intimidate</a> (Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#jump">Jump</a> (Str),
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#listen">Listen</a> (Wis), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#spot">Spot</a> (Wis), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#survival">Survival</a> (Wis), and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#swim">Swim</a> (Str).
    </p>
    <p>
      <b>Skill Points at Each Level:</b> 2 + Int modifier.
    </p>
    <h6><a id="orc-paragon-class-features"></a>
Class Features
    </h6>
    <p>
All of the following are class features of the orc paragon class.
    </p>
    <p>
      <b>Weapon and Armor Proficiency:</b> Orc paragons are proficient
with all simple and martial weapons and with light and medium
armor.
    </p>
    <p>
      <a id="orc-paragon-improved-darkvision"></a><b>Improved Darkvision (Ex):</b> At 1st level, an orc paragon's
darkvision range increases by 30 feet.
    </p>
    <p>
      <a id="orc-paragon-no-light-sensitivity"></a><b>No Light Sensitivity:</b> An orc paragon loses his race's light
sensitivity at 1st level.
    </p>
    <p>
      <a id="orc-paragon-elf-slayer"></a><b>Elf Slayer (Ex):</b> A 2nd-level orc paragon learns to channel
his hatred of the elven race. He gains a +2 bonus on weapon damage
rolls against <a style={{
        "color": "#579eb6"
      }} href="monstersEtoF.html#elf">elves</a>.
    </p>
    <p>
      <a id="orc-paragon-ability-boost"></a><b>Ability Boost (Ex):</b> At 3rd level, an orc paragon's Strength
score increases by 2 points.
    </p>
    <h3><a id="tiefling-paragon"></a>
TIEFLING PARAGON
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/UnA_Gallery/79144.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a>
Tieflings are humans with traces of fiendish ancestry. Although
tieflings are not as powerful or as potent as full-fledged
half-fiends, their touch of fiendish blood does grant them
significant bonuses to their physical and mental abilities as well
as some minor resistances and spell-like abilities. Although a few
tieflings manage to overcome their ancestry and remain good and
trustworthy individuals, most tieflings follow the dark urges
tugging at them, becoming selfish, cruel, and evil as they mature.
Tiefling paragons, both good and evil, are those who most
successfully come to terms with their family's history. The good
ones see themselves as rising above their ancestry, while the evil
ones embrace its power.
    <p>
      <a id="tiefling-paragon-adventures"></a><b>Adventures:</b> Tiefling paragons undertake adventures for the
usual reasons of gaining wealth, prestige, or power. Evil
tieflings, in particular, adventure to fulfill selfish desires � to
gain powerful magic or find lost knowledge that will give them an
advantage in some conflict. Good tiefling paragons typically
adventure to prove their worth to a community, to stick with the
few close companions they have managed to find, or simply to get
away from the press of civilization.
    </p>
    <p>
      <a id="tiefling-paragon-characteristics"></a><b>Characteristics:</b> Tiefling paragons are stealthy, deceptive,
and often selfish. Trusted by few, they in turn trust no one,
knowing that, no matter their behavior, they'll likely be
suspected of wrongdoing as soon as any problem arises in the
community. Because of such hard lessons in dealing with other
races, tiefling paragons are suspicious of kindness and almost
never trust that a member of another race will remain loyal or
friendly once the tiefling's ancestry is known. Many tiefling
paragons, even those who aren't truly evil, have an appetite for
cruelty that is difficult to suppress.
    </p>
    <p>
      <a id="tiefling-paragon-alignment"></a><b>Alignment:</b> Almost all tiefling paragons are evil. Those
that are not must constantly struggle against thoughts of violence
and corruption, seldom knowing the peace that many other
goodaligned creatures take for granted.
    </p>
    <p>
      <a id="tiefling-paragon-religion"></a><b>Religion:</b> Most tiefling paragons steer clear of religion,
knowing that many followers of good deities see it as their duty
to expose or attack tieflings. Evil tiefling paragons involved
with evil cults (typically dedicated to powerful fiends of some
kind) are an exception.
    </p>
    <p>
      <a id="tiefling-paragon-background"></a><b>Background:</b> Tiefling paragons learn their skills while
slipping quietly through the societies of other races. They
frequently multiclass as <a style={{
        "color": "#579eb6"
      }} href="rogue.html">rogues</a>, and often have similar
backgrounds. A lucky few tiefling paragons have mentors and
opportunities to learn in comfortable environments, but the vast
majority must overcome the prejudices of those who fear the
tiefling's ancestry, learning their skills among criminals and in
back alleys.
    </p>
    <p>
Tiefling paragons do not see themselves as fellows or champions of
their race. Instead, if evil, they see themselves as champions of
evil and representatives of their fiendish forefathers. Good
tiefling paragons seek to absolve themselves of the reputation
imposed upon them by their ancestry.
    </p>
    <p>
      <a id="tiefling-paragon-races"></a><b>Races:</b> Tiefling paragons have widely differing attitudes
about the humanoid races, depending on alignment and their
experiences in the communities of different races. Although they
can get along in many societies, tiefling paragons prefer large
human cities, where it's generally easier to conceal their
ancestry. Tiefling paragons rarely have time for dwarves, mostly
because dwarves are likely to be suspicious and critical of the
tiefling's presence. Conversely, they empathize with the prejudice
and difficulties that half-orcs face in many civilized communities
and can form friendships based on this shared perspective.
    </p>
    <p>
      <a id="tiefling-paragon-other-classes"></a><b>Other Classes:</b> Tiefling paragons are happy to work with
members of other classes, provided that the tiefling can
manipulate events to her own ends, make off with choice treasure
at opportune moments, or gather information for later use. Evil
tieflings, naturally, avoid the company of <a style={{
        "color": "#579eb6"
      }} href="paladin.html">paladins</a>, but those
tieflings who have dedicated themselves to good actually prefer
the company of holy warriors, knowing that a paladin's word will
go a long way toward assuring others that the tiefling can be
trusted.
    </p>
    <h5><a id="tiefling-paragon-game-rule-information"></a>
GAME RULE INFORMATION
    </h5>
    <b><a id="table-the-tiefling-paragon"></a>Table: The Tiefling Paragon</b>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "80%"
    }}>
      <tbody>
        <tr>
          <th>
Level
          </th>
          <th>
Base
Attack
Bonus
          </th>
          <th>
Fort
Save
          </th>
          <th>
Ref
Save
          </th>
          <th>
Will
Save
          </th>
          <th align="left">
Special
          </th>
        </tr>
        <tr className="odd-row">
          <td align="center">
1st
          </td>
          <td align="center">
+0
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+2
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#tiefling-paragon-improved-resistance">Improved resistance</a>
          </td>
        </tr>
        <tr>
          <td align="center">
2nd
          </td>
          <td align="center">
+1
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+3
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#tiefling-paragon-darkness">Darkness</a>, <a style={{
              "color": "#579eb6"
            }} href="#tiefling-paragon-skill-bonus">skill bonus</a>
          </td>
        </tr>
        <tr className="odd-row">
          <td align="center">
3rd
          </td>
          <td align="center">
+2
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+3
          </td>
          <td align="center">
+3
          </td>
          <td>
            <a style={{
              "color": "#579eb6"
            }} href="#tiefling-paragon-ability-boost">Ability boost</a> (Dex +2)
          </td>
        </tr>
      </tbody>
    </table>
    <p>
Tiefling paragons have the following game statistics.
    </p>
    <p>
      <b>Abilities:</b> Because they tend to favor light armor, agile
combat styles, and stealth whenever possible, Dexterity is
extremely important to tiefling paragons. A high Intelligence
grants the tiefling paragon access to more skills at each level,
and a high Constitution helps compensate for the Hit Die lost
because of the tiefling's level adjustment. Although tieflings
take a racial penalty to Charisma, a good score in this ability
can help those tiefling paragons who wish to conceal their nature
from others and gain acceptance whenever their true nature becomes
known.
    </p>
    <p>
      <b>Alignment:</b> Any.
    </p>
    <p>
      <b>Hit Die:</b> d8.
    </p>
    <h6><a id="tiefling-paragon-class-skills"></a>
Class Skills
    </h6>
    <p>
The tiefling paragon's class skills (and the key ability for each
skill) are <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#bluff">Bluff</a> (Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#craft">Craft</a> (Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#disguise">Disguise</a> (Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#hide">Hide</a> (Dex),
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#knowledge">Knowledge</a> (the planes) (Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#listen">Listen</a> (Wis), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#move-silently">Move Silently</a> (Dex),
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#profession">Profession</a> (Wis), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#sleight-of-hand">Sleight of Hand</a> (Dex), and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#spot">Spot</a> (Wis).
    </p>
    <p>
      <b>Skill Points at Each Level:</b> 4+ Int modifier.
    </p>
    <h6><a id="tiefling-paragon-class-features"></a>
Class Features
    </h6>
    <p>
All of the following are class features of the tiefling paragon
class.
    </p>
    <p>
      <b>Weapon and Armor Proficiency:</b> Tiefling paragons are
proficient with all simple weapons and with light armor.
    </p>
    <p>
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#improved-resistance"></a><b>Improved Resistance (Ex):</b> At 1st level, one of a tiefling
paragon's natural resistances to energy (fire, cold, or
electricity) improves from 5 to 10. The character can choose which
of her three resistances improves, but once she makes this choice
it can never be altered.
    </p>
    <p>
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#darkness"></a><b>Darkness (Sp):</b> At 2nd level, a tiefling paragon can use her
      <a style={{
        "color": "#579eb6"
      }} href="spellsDtoE.html#darkness">darkness</a> spell-like ability three times per day.
    </p>
    <p>
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#skills-bonus"></a><b>Skill Bonus (Ex):</b> A 2nd-level tiefling paragon's racial
bonus on <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#bluff">Bluff</a> and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#hide">Hide</a> checks improves to +4.
    </p>
    <p>
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#ability-boost"></a><b>Ability Boost (Ex):</b> At 3rd level, a tiefling paragon's
Dexterity score increases by 2 points.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      